import * as React from "react";
import { Link } from "gatsby";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest";

export default function SocialFollow() {
  return (
    <div className="social-follow-container">
      <div>
        <Link to="https://www.facebook.com/kristielengel14/">
          <span className="icon">
            <FaFacebook />
          </span>
        </Link>
      </div>
      <div>
        <Link to="https://www.youtube.com/c/KristieLengel">
          <span className="icon">
            <FaYoutube />
          </span>
        </Link>
      </div>
      <div>
        <Link to="https://www.instagram.com/kristielengel/">
          <span className="icon">
            <FaInstagram />
          </span>
        </Link>
      </div>
      <div>
        <Link to="https://pin.it/1i6GgW7">
          <span className="icon">
            <FaPinterest />
          </span>
        </Link>
      </div>
    </div>
  );
}
