import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";
import SocialFollow from "./SocialFollow";
import * as ROUTES from "../../constants/routes";

export default function Funnel() {
  return (
    <div className="funnel">
      <div className="funnel-image">
        <StaticImage
          src={"../../img/landing/funnel-hero.png"}
          loading="lazy"
          alt="Frank and Kristie planking while giving a high five."
        />
      </div>
      <div>
        <p className="blurb first title">
          Transform Your Life with Balance: Workouts for Busy Women!
        </p>
        <p className="blurb description">
          Break free from the vicious cycle of start again/stop again "fad"
          fitness routines and diets that never work long-term.
        </p>
      </div>
      <div className="app-store">
        <div>
          <a href="https://apps.apple.com/us/app/balance-workouts-for-women/id6447655679">
            <StaticImage
              src={"../../img/app-store-badge.png"}
              alt="Download one the App Store official badge"
            />
          </a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.frankcalise.kristielengel">
            <StaticImage
              src={"../../img/google-play-badge.png"}
              alt="Get it on Google Play official badge"
            />
          </a>
        </div>
      </div>
      <div className="app-store download">
        <FaArrowUp />
        <p className="blurb first download">
          Download the App for Your Free Trial!
        </p>
        <FaArrowUp />
      </div>
      <div>
        <p className="blurb second">
          Hey ladies! It's Kristie! Also known as &quot;Cheese&quot; 😜 The
          entirety of my 20's was spent living a completely unbalanced
          lifestyle. I was super ambitious and put everything I had into being
          successful! Whether it was owning my own fitness studio, competing in
          natural bodybuilding, running the most successful franchise gym
          location in the country, or owning a meal prep restaurant (Version
          #2), I would push myself so hard that I would get to a point that I
          just couldn't push anymore! I'd then find myself on the opposite end
          of unhealthy, fighting depressive feelings and completely neglecting
          my health and career goals (Version 1). I lived a totally &quot;black
          and white&quot; life!
        </p>
        <p className="blurb second">
          But now, my lifestyle revolves around living in the GRAY! I preach
          #BALANCE! I scream it! Heck, I even got the word tattooed on the back
          of my neck! I've tossed extremes to the curb and give myself constant
          grace. I strive for #BALANCE mentally, emotionally and physically, and
          I'm so excited to help you do the same! No more yo-yo's. No more
          depriving and binging. No more starting over on Monday!
        </p>
      </div>
      <div className="funnel-image">
        <StaticImage
          src={"../../img/landing/funnel-versions.png"}
          loading="lazy"
          alt="An image comparing two versions of Kristie. Version 1, 142 pounds, overindulging, inconsistent exercise, hormone imbalance and body image issues. Version 2, 118 pounds, deprived/starving, abusing caffeine, high blood pressure, panic attacks and depression"
          style={{ border: "1px solid #535c5c" }}
        />
      </div>
      <div>
        <p className="blurb first title">
          Your Journey to a Balanced, Healthy Life Starts Here!
        </p>
        <p className="blurb description long">
          Kristie has helped thousands of busy women lose thousands of pounds,
          so if you’re sick of starting over every Monday and ready to stick to
          a program once and for all, then the #Balance community is for YOU!
        </p>
      </div>
      <div className="mobile-images">
        <div className="funnel-image">
          <StaticImage
            src={"../../img/landing/funnel-mobile-1.png"}
            loading="lazy"
            alt="A screenshot of the mobile application displaying the welcome screen."
            style={{ border: "1px solid #535c5c" }}
            width={600}
          />
        </div>
        <div className="funnel-image">
          <StaticImage
            src={"../../img/landing/funnel-mobile-2.png"}
            loading="lazy"
            alt="A screenshot of the mobile application displaying the welcome screen."
            style={{ border: "1px solid #535c5c" }}
            width={600}
          />
        </div>
      </div>
      <div className="mobile-images">
        <div className="funnel-image">
          <StaticImage
            src={"../../img/landing/funnel-mobile-3.png"}
            loading="lazy"
            alt="A screenshot of the mobile application displaying the welcome screen."
            style={{ border: "1px solid #535c5c" }}
            width={600}
          />
        </div>
        <div className="funnel-image">
          <StaticImage
            src={"../../img/landing/funnel-mobile-4.png"}
            loading="lazy"
            alt="A screenshot of the mobile application displaying the welcome screen."
            style={{ border: "1px solid #535c5c" }}
            width={600}
          />
        </div>
      </div>
      <div>
        <p className="blurb first title">Features:</p>
        <p>
          <ul>
            <li>
              -5 new workouts EVERY WEEK programmed to get busy women results!
            </li>
            -A mix of HIIT, strength training, barre, bodyweight training,
            recovery, mobility & more!
            <li>-Minimal equipment (just dumbbells!)</li>
            <li>-50+ workout video library </li>
            <li>
              -Access to 1:1 chats with your personal Exercise Physiologist,
              Kristie!
            </li>
            <li>-Recipes and balanced nutrition guidance</li>
            <li>-Members-only LIVE events </li>
            <li>-Nationwide community support </li>
            <li>-Virtual community challenges </li>
            <li>-24/7 access to #Balance</li>
          </ul>
        </p>
      </div>
      <div>
        <p className="blurb first title">
          Start Your #Balance Experience Today!
        </p>
        <p className="blurb description long">
          Don't wait another day to achieve the balance and healthy lifestyle
          you deserve. Download the Balance: Workouts for Women app now and
          start your free trial.
        </p>
        <p className="blurb description long">
          Transform your health and become the best version of yourself –
          healthy, energetic, comfortable in your own body and clothes, making
          healthy choices effortlessly, and experiencing a newfound sense of
          confidence, fulfillment, and overall well-being.
        </p>
        <p className="blurb description long">
          You'll love being a part of our supportive community of busy women and
          moms just like you who are on a journey toward a balanced and
          fulfilling life.
        </p>
      </div>
      <div className="app-store">
        <div>
          <a href="https://apps.apple.com/us/app/balance-workouts-for-women/id6447655679">
            <StaticImage
              src={"../../img/app-store-badge.png"}
              alt="Download one the App Store official badge"
            />
          </a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.frankcalise.kristielengel">
            <StaticImage
              src={"../../img/google-play-badge.png"}
              alt="Get it on Google Play official badge"
            />
          </a>
        </div>
      </div>
      <div className="app-store download">
        <FaArrowUp />
        <p className="blurb first download">
          Download the App for Your Free Trial!
        </p>
        <FaArrowUp />
      </div>
      <div className="link-buttons">
        <ul>
          <Link to={ROUTES.BALANCE_BOOT_CAMP}>
            <li>FREE 7-Day E-Course #Balance Boot Camp</li>
          </Link>
          <Link to={ROUTES.SIGN_UP}>
            <li>Quick and convenient home workouts! Just $29.99! 💪</li>
          </Link>
          <Link to={ROUTES.FOREVER_BALANCE}>
            <li>Forever #BALANCED 1:1 Coaching! 🙌</li>
          </Link>
          <Link to={ROUTES.BALANCE_PODCAST}>
            <li>#Balance Banter Podcast! 🎤</li>
          </Link>
          <Link to={ROUTES.BLOG}>
            <li>Recipes/Blogs! 🤤</li>
          </Link>
          <Link to={ROUTES.SIGN_IN}>
            <li>MEMBERS Sign In 🗝️​</li>
          </Link>
        </ul>
      </div>

      <div className="funnel-video">
        <EmbedYouTubeVideo
          url="https://www.youtube.com/watch?v=8utjARAfWLo"
          title="Find your #BALANCE"
        />
      </div>

      <SocialFollow />
    </div>
  );
}
