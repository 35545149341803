import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { navigate } from "gatsby";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import Layout from "../components/Layout";
import Funnel from "../components/Funnel";
import * as ROUTES from "../constants/routes";
import Seo from "../components/seo";

import { useAuthUser } from "../components/Session";
import { withFirebase } from "../components/Firebase";

const IndexMemberPageTemplate = loadable(() => import("./index-member-page"));

function IndexPageTemplate() {
  return <Funnel />;
}

const IndexTemplate = ({ firebase, ...props }) => {
  const authUser = useAuthUser();
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  if (!firebase || !isClient) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "30rem",
        }}
      >
        <FaSpinner
          className="fa-spin"
          size={40}
          style={{ marginRight: "1rem", verticalAlign: "middle" }}
        />{" "}
        Loading...
      </div>
    );
  }

  if (authUser) {
    if (
      authUser.subscription_status === "canceled" ||
      authUser.subscription_status === "unpaid"
    ) {
      navigate(ROUTES.CANCELED);
      return null;
    }

    return <IndexMemberPageTemplate {...props} />;
  }

  return <IndexPageTemplate {...props} />;
};

const TemplateWrapper = withFirebase(IndexTemplate);

const IndexPage = ({ data }) => {
  // const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      {/* <ConstantContactSignUp /> */}
      <Seo
        title="Home"
        description={`Kristie Lengel offers a "no quick fix" approach to fitness and nutrition with her convenient home workouts and balanced lifestyle guidance for busy women.`}
      />
      <TemplateWrapper />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;
